<script setup lang="ts">
const isExpanded = ref(false);

const props = defineProps<{
  expandedText: string;
  maxChars: number;
}>();

const showedText = computed(() => {
  return isExpanded.value || props.expandedText.length <= props.maxChars
    ? props.expandedText
    : `${props.expandedText.slice(0, props.maxChars)}...`;
});
</script>

<template>
  <div
    class="[&__ul]:ml-5 [&__ul_li]:list-disc"
    v-html="showedText"
    @click.stop="isExpanded = true"
  ></div>
  <div
    v-if="expandedText.length > maxChars"
    @click.stop="isExpanded = !isExpanded"
    class="cursor-pointer font-semibold text-primary"
  >
    {{ isExpanded ? 'Zobacz mniej' : 'Zobacz więcej' }}
  </div>
</template>
